<template>
  <div class="finances">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova subcategoria</span>
    </button>
    <b-table
      :data="allFinancialSubCategories"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Categoria">
              <b-select
                placeholder="Selecione uma categoria"
                required
                v-model="model.financial_category_id"
                expanded
              >
                <option
                  v-for="(item, index) in allFinancialCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Imposto">
              <b-select placeholder="Atribuir imposto" v-model="model.fee" expanded>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </b-select>
            </b-field>
            <b-field v-if="model.fee === 'true'" label="Tipo">
              <b-select placeholder="Selecione o tipo de imposto" expanded v-model="model.type">
                <option value="%">%</option>
                <option value="static">R$</option>
              </b-select>
            </b-field>
            <b-field v-if="model.fee === 'true'" label="Valor">
              <b-input v-money="moneyFormat" required v-model="model.value"></b-input>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/financial-subcategories/store/service'

export default {
  name: 'FinancialSubCategories',
  data() {
    return {
      model: { name: null },
      isCardModalActive: false,
      moneyFormat: {
        prefix: '',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('financialSubCategories', ['allFinancialSubCategories']),
    ...mapState('financialCategories', ['allFinancialCategories']),
  },
  methods: {
    ...mapActions('financialSubCategories', ['getAllFinancialSubCategories']),
    ...mapActions('financialCategories', ['getAllFinancialCategories']),
    async init() {
      await this.getAllFinancialSubCategories()
      await this.getAllFinancialCategories()

      console.log(
        ' cat ',
        this.allFinancialCategories,
        ' sub ',
        this.allFinancialSubCategories
      )
    },
    onEdit(item) {
      this.model = { ...item }
    },
    async save() {
      const financialSubCategory = {
        ...this.model,
        fee: this.model.fee === "true" ? true : false
      }

      try {
        const save = financialSubCategory.id
          ? await service.updateFinancialSubCategory(financialSubCategory)
          : await service.saveFinancialSubCategory(financialSubCategory)

        await this.$success('Subcategoria')
        location.reload(true)
      } catch (error) {
        this.$error(error.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a subcategoria?')

        if (result.isConfirmed) {
          await service.deleteFinancialSubCategory(id)

          await this.$delete('Subcategoria')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
